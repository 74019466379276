@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/variables' as *;

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $beige;
    @include section;

    &__txt {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__quote {
        @include quote;
        width: 75%;
        color: $white;
        margin-bottom: $margin*2;

        &-link {
            @include link-style-reset-reverse;

        }
    }

    &__link {
        @include link-style-reset-reverse;
    }

    &__links {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: $padding*6 0;
        border-top: 2px solid $black;
    }

    &__logo {
        @include logo-style-reset;
        @include small-logo;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    &__social {
        display: flex;
        flex-direction: column;

        &-link {
            @include link-style-reset;
            @include text;
            
            &:not(:last-child) {
                margin-bottom: $margin*2;
            }
        }
    }
}