@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/variables' as *;

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding-bottom: $padding*6;
    @include section;

    &__wrap {
        width: 75%;
        display: flex;
        flex-direction: column;
        padding: 3rem 0;
    }

    &__quote {
        @include quote;
    }

    &__location {
        @include subquote;
        width: 75%;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 1rem;
        width: 100%;
    }

    &__btn {
        @include button;
    }
}