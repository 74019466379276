
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/colors' as *;

.projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding-top: $padding*20;
    padding-bottom: $padding*20;
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @include section;

    @include tablet {
        flex-direction: row;
    }

    &:not(:last-child) {
        margin-bottom: $margin*2;

        @include tablet {
            margin-bottom: $margin*4;
        }

        @include desktop {
            margin-bottom: $margin*8;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: $margin*2;
        width: 100%;
        // background-color: purple;

        @include tablet {
            width: 50%;
            margin-bottom: 0;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: $margin*2;
        // background-color: pink;

        @include tablet {
            width: 50%;
            margin-bottom: 0;
        }
    }

    // Alternating image/text containers for Projects
    &:nth-child(odd) .project__left {
        @include tablet {
            order: 1;
            padding-right: $padding*4;
            align-items: flex-end;
        }
    }
    
    &:nth-child(odd) .project__right {
        @include tablet {
            order: 2;
            padding-left: $padding*4;
        }
    }
    
    &:nth-child(even) .project__left {
        @include tablet {
            order: 2;
            padding-left: $padding*4;
            align-items: flex-start;
        }
    }
    
    &:nth-child(even) .project__right {
        @include tablet {
            order: 1;
            align-items: flex-end;
            padding-right: $padding*4;

            & > .project__technologies {
                justify-content: flex-end;
            }
        }

        & .project__description {
            @include tablet {
                text-align: right;
            }
        }
    }

    &__title {
        font-family: $title-font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 1.75rem;
        margin-bottom: $margin;
        color: $black;

        @include tablet {
            font-size: 2rem;
        }
    }

    &__description {
        font-family: $main-font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        width: 100%;

        @include tablet {
            font-size: 1.5rem;
            max-width: 70%;
        }

        @include desktop {
            max-width: 60%;
        }
    }

    &__technologies {
        display: flex;
        flex-wrap: wrap;
        padding: $padding*2 0;
        width: 100%;

        @include tablet {
            max-width: 70%;
        }

        @include desktop {
            max-width: 60%;
        }
    }

    &__technology {
        margin-right: $margin;
        font-family: $main-font-family;
        font-style: normal;
        font-weight: 300;
        font-size: 1.25rem;

        @include tablet {
            font-size: 1.5rem;
        }

        &:not(:last-child)::after {
            content: ",";
        }
    }

    &__buttons {
        display: flex;
    }

    &__btn {
        @include button;
    }

    &__link:not(:last-child) {
        margin-right: $margin*2;
    }

    &__img {
        display: flex;
        justify-content: center;
        align-items: center;

            &-portrait {
                width: 100%;
            }

            &-landscape {
                width: 100%;

                @include tablet {
                    width: auto;
                    height: 100%;
                }
            }

        &-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-height: 70vh;
            overflow: hidden;
            background-color: pink;

            @include tablet {
                width: 300px;
                height: 300px;
            }

            @include desktop {
                width: 400px;
                height: 400px;
            }
        }
    }
}