@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;

.navigation {
    z-index: 20000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    &--show {
        display: flex;
    }

    &--hide {
        display: none;
    }
}

.navbar {
    &__link {
        @include menu;
        cursor: pointer;

        &:hover {
            color: $beige;
        }

        &--active {
            text-decoration: underline;
        }
    }
}