@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/variables' as *;

.header {
    z-index: 21000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: $beige2;
    background-color: $white;
    border-bottom: 1px solid $black;

    &__wrap {
        width: 90vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        @include logo;
        @include logo-style-reset;
    }

    &__btn {
        cursor: pointer;
        display: flex;
    }

    &__icon {
        max-width: 30px;
        max-height: 30px;
    }
}

.navbar {
    display: flex;
    flex-direction: column;
    
    // &__link {

    //     &--active {

    //     }

    // }
}